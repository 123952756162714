import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/owenb/Documents/ems/ems-website/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Login Screen`}</h1>
    <p>{`The login screen allows for login through Google or Apple.
There is also guest mode for those who do not want a login.
Lastly, the bottom provides links about the app.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "240px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b64d97d1916a5114fb52d91937aa1b86/ec605/login_screen.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "195.00000000000003%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAnABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQCAwH/xAAYAQADAQEAAAAAAAAAAAAAAAAAAgMEAf/aAAwDAQACEAMQAAABrxRy1ZOylG7FBG8acYAAP//EABsQAQEAAgMBAAAAAAAAAAAAAAIBAAMRIDMi/9oACAEBAAEFAkSV84NIR2enOavNA3IBckknb//EABoRAAICAwAAAAAAAAAAAAAAAAABAhESIDH/2gAIAQMBAT8BfRQjRitP/8QAGhEAAgIDAAAAAAAAAAAAAAAAAAECERIgMf/aAAgBAgEBPwFcHOVmT0//xAAfEAABAwQDAQAAAAAAAAAAAAAAAQIREiIyYSAjM7H/2gAIAQEABj8CppcYuJuQXtjR6/RLqtkq0xITn//EAB4QAAMAAQQDAAAAAAAAAAAAAAABEUEQICHhMcHw/9oACAEBAAE/IXeiZvR8n0JU0w2O/YOCi5AGzUbG3iTREtIljb//2gAMAwEAAgADAAAAEBTOwAAP/8QAGREBAAIDAAAAAAAAAAAAAAAAAQAREEHB/9oACAEDAQE/EDSDkYFJbqBWf//EABkRAQACAwAAAAAAAAAAAAAAAAEAERBBwf/aAAgBAgEBPxB2F7CIGU7i3n//xAAeEAEAAgICAwEAAAAAAAAAAAABACERMUFhIFFxof/aAAgBAQABPxAFHw1YajXP5YR6M6GS/kGIjVPR1O/CUuS+V33F5Et5YoMVdwGPWDiN7gigfDx//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Login button",
            "title": "Login button",
            "src": "/static/b64d97d1916a5114fb52d91937aa1b86/09b79/login_screen.jpg",
            "srcSet": ["/static/b64d97d1916a5114fb52d91937aa1b86/59e87/login_screen.jpg 60w", "/static/b64d97d1916a5114fb52d91937aa1b86/e439a/login_screen.jpg 120w", "/static/b64d97d1916a5114fb52d91937aa1b86/09b79/login_screen.jpg 240w", "/static/b64d97d1916a5114fb52d91937aa1b86/158ba/login_screen.jpg 360w", "/static/b64d97d1916a5114fb52d91937aa1b86/7cc5e/login_screen.jpg 480w", "/static/b64d97d1916a5114fb52d91937aa1b86/ec605/login_screen.jpg 1125w"],
            "sizes": "(max-width: 240px) 100vw, 240px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`Login with Apple`}</h3>
    <p>{`Login with Apple for Response Time Track.
Apple may ask if you'd like your email shared, which is optional.`}</p>
    <h3>{`Login with Google`}</h3>
    <p>{`Login with Google for Response Time Track.`}</p>
    <h3>{`Continue as guest`}</h3>
    <p>{`Continue as guest for Response Time Track.
For guest, there is no premium subscription purchase allowed.`}</p>
    <h3>{`Links`}</h3>
    <p>{`Below links are for release notes, documentation, and privacy policy`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      